<template>
  <div>
    <div class="van-main">
      <div class="van-common-detail">
        <van-collapse>
          <template v-for="item in serviceMenuList">
            <van-cell v-if="item.children.length === 0" :key="item.id" is-link :to="item.routing" class="call-class">
              <span :class="'icon iconfont ' + item.menuIcon" />
              <span>{{ item.menuName }}</span>
            </van-cell>
            <van-collapse-item v-else :key="item.id" :title="item.menuName" :name="item.id">
              <template #default>
                <div>
                  <div v-for="item1 in item.children" :key="item1.id">
                    <span :class="'icon iconfont ' + item1.menuIcon" />
                    <van-cell :icon="item1.menuIcon" title="项目管理" is-link :to="item1.routing" />
                  </div>
                </div>
              </template>
            </van-collapse-item>
          </template>
        </van-collapse>
      </div>
    </div>
    <tab-bar active="service" />
  </div>
</template>

<script>
import TabBar from '@/components/tab-bar'
import { Collapse, CollapseItem, Cell, CellGroup } from 'vant'
export default {
  components: {
    TabBar,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data () {
    return {
      serviceMenuList: [],
      userType: this._.get(this.$store.state, 'employeeInfo.userType', '')
    }
  },
  created () {
    this.getMenu()
  },
  methods: {
    getMenu () {
      this.api.base.getMenu({ menuType: 2 }).then(result => {
        let parentMenuId = this.constants.serviceMenu.platFormParentId
        if (this.userType === 4 || this.userType === 5) {
          parentMenuId = this.constants.serviceMenu.enterpriseParentId
        }
        const menuList = result.data.value
        const serviceMenu = this._.find(menuList, { menuId: parentMenuId }) || {}
        console.log(serviceMenu.children)
        if (serviceMenu.children) {
          serviceMenu.children.forEach(item => {
            switch (item.menuId) {
              case '4c2cc2aa0ecc4516bfe44ef3a5f883f6':
                item.menuIcon = 'icon-xiangmu'
                break
              case 'ae03d9e6d3df4f4e90fff1f7edd44017':
                item.menuIcon = 'icon-hetong'
                break
              case '9de3d8ef076c4360b92d5eafe9384e60':
                item.menuIcon = 'icon-dingdanguanli'
                break
              case 'dc4636052db1411c9406b14173ab5128':
                item.menuIcon = 'icon-zhcc_tidan'
                break
              case '3442b03e91c74ffdbd7ee0c26e0fcf79':
                item.menuIcon = 'icon-shiwuzhongxin_huiyitidan'
                break
              case 'd533bb30a5be47d7b0e87862aafeb3b9':
                item.menuIcon = 'icon-cangku'
                break
              case 'ee79f86e4b504ecd84064a421c74ba72':
                item.menuIcon = 'icon-shoukuan'
                break
              case '28361f2aa5fd4eb080abb9ff55ebf6bd':
                item.menuIcon = 'icon-fukuan'
                break
              case '04bf5c0409f0430c86bee52185935ac9':
                item.menuIcon = 'icon-fapiaoguanli'
                break
              case 'c90ce25750604f81bd8b08659bad4f3a':
                item.menuIcon = 'icon-zijinguanli'
                break
              case '743d35abee5e47b69e26893cc99deca5':
                item.menuIcon = 'icon-fengkongguanli'
                break
              case '3fa628ad1942451ea765221595a73dd6':
                item.menuIcon = 'icon-dingdanguanli'
                break
              case 'dcf97d09186c4e7c82b81522211b7a30':
                item.menuIcon = 'icon-hetong'
                break
              case '68e34ce992f347e7ab7e5059ebbc0eb6':
                item.menuIcon = 'icon-zhcc_tidan'
                break
              case '3988e2f709cf4e19b551f2be0e1c795c':
                item.menuIcon = 'icon-huowu'
                break
              case '68c59e45c21a44a7b97dc86210e1103a':
                item.menuIcon = 'icon-fukuan1'
                break
              case 'c0053059988d46feb733ca9ec3ff38c4':
                item.menuIcon = 'icon-fapiaoguanli'
                break
              default:
                item.menuIcon = ''
                break
            }
          })
        }
        this.serviceMenuList = serviceMenu.children || []
      })
    }
  }
}
</script>

<style scoped>
  html {
    background: #f2f2f2 !important;
  }
  .van-common-detail{
    padding-bottom: 55px;
  }
  .call-class{
    border-bottom: 5px solid #f2f2f2 !important;
  }
  .call-class >>> img{
    padding: 0px 10px 0px 5px !important;
  }
  .iconfont{
    padding-right: 10px;
    vertical-align: middle;
  }
</style>
