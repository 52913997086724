var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "van-main" }, [
        _c(
          "div",
          { staticClass: "van-common-detail" },
          [
            _c(
              "van-collapse",
              [
                _vm._l(_vm.serviceMenuList, function(item) {
                  return [
                    item.children.length === 0
                      ? _c(
                          "van-cell",
                          {
                            key: item.id,
                            staticClass: "call-class",
                            attrs: { "is-link": "", to: item.routing }
                          },
                          [
                            _c("span", {
                              class: "icon iconfont " + item.menuIcon
                            }),
                            _c("span", [_vm._v(_vm._s(item.menuName))])
                          ]
                        )
                      : _c("van-collapse-item", {
                          key: item.id,
                          attrs: { title: item.menuName, name: item.id },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      _vm._l(item.children, function(item1) {
                                        return _c(
                                          "div",
                                          { key: item1.id },
                                          [
                                            _c("span", {
                                              class:
                                                "icon iconfont " +
                                                item1.menuIcon
                                            }),
                                            _c("van-cell", {
                                              attrs: {
                                                icon: item1.menuIcon,
                                                title: "项目管理",
                                                "is-link": "",
                                                to: item1.routing
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      ]),
      _c("tab-bar", { attrs: { active: "service" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }